<template>
  <v-dialog
    v-model="dialog"
    max-width="420"
    :persistent="savingUser"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-account-remove-outline</v-icon>
        <div class="text-truncate">
          <span>Benutzer löschen</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :disabled="savingUser"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text class="py-6 text-center">
        <div>
          Wirklich
          <strong>{{ editedUser?.displayName }}</strong>
          (<strong>{{ editedUser?.email }}</strong>)
          löschen?
        </div>
        <div>
          Diese Aktion kann nicht rückgängig gemacht werden.
        </div>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="savingUser"
          @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="error"
          :loading="savingUser"
          @click="submit()"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'DialogDelete',
  data: () => ({
    error: null,
  }),
  computed: {
    ...mapState('manage/users', [
      'editedUser',
      'savingUser',
      'selectedUserIri',
    ]),
    dialog: {
      get() {
        return this.$store.state.manage.users.dialogDelete
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/closeDialog')
        }
      },
    },
  },
  watch: {
    dialog() {
      this.error = null
    },
  },
  methods: {
    async submit() {
      this.error = null
      const { dispatch } = this.$store
      try {
        await dispatch('manage/users/removeUser', this.editedUser)
      } catch (e) {
        this.error = e
      }
      if (!this.error) {
        dispatch('manage/users/fetchUsers', {silent: true})
        if (this.editedUser['@id'] == this.selectedUserIri) {
          dispatch('manage/users/selectUser', null)
        }
        this.dialog = false
      }
    },
  },
}
</script>
