<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :search="search"
    item-key="@id"
    :item-class="(item) => ({'font-weight-bold': isCurrentUser(item)})"
    dense
    @click:row="(item) => selected = [item]"
  >

    <template v-slot:top>
      <v-row
        dense
        class="pa-3"
      >
        <v-col
          cols="12"
          sm="6"
          md="4"
          order="1"
        >
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Name, Email, Lehrkraft"
            single-line
            hide-details
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          order="2"
        >
          <RolesSelect v-model="roles"/>
        </v-col>
        <v-col
          class="text-right py-2"
          cols="12"
          md="4"
          order="0"
          order-md="3"
        >
          <v-btn
            color="primary"
            small
            @click="$store.dispatch('manage/users/openDialog')"
          >
            <v-icon
              left
              size="18"
            >mdi-plus</v-icon>
            Benutzer hinzufügen
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-slot:item.avatar="{ item }">
      <v-avatar
        v-if="item.avatar"
        size="30"
      >
        <v-img :src="item.avatar" />
      </v-avatar>
    </template>

    <template v-slot:item.email="{ item }">
      <v-icon
        v-if="isCurrentUser(item)"
        small
        color="grey"
      >mdi-lock-open-variant</v-icon>
      {{ item.email }}
    </template>

    <template v-slot:item.roles="{ item }">
      <RolesChips :roles="item.roles"/>
    </template>

    <template v-slot:item.isActive="{ item }">
      <StatusChip :is-active="item.isActive" />
    </template>

    <template v-slot:item.lastLogin="{ item }">
      {{ item.lastLogin?.toLocaleDateString('de') }}
    </template>

  </v-data-table>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import RolesChips from './roles-chips'
import RolesSelect from './roles-select'
import StatusChip from './status-chip'

export default {
  name: 'TableUsers',
  components: {
    RolesChips,
    RolesSelect,
    StatusChip,
  },
  data: () => ({
    roles: [],
    search: '',
  }),
  computed: {
    ...mapState('manage/users', [
      'users',
    ]),
    ...mapGetters('manage/users', [
      'isCurrentUser',
      'selectedUser',
    ]),
    ...mapGetters('common', [
      'teacherByIri',
    ]),
    selected: {
      get() {
        return this.selectedUser ? [this.selectedUser] : []
      },
      set([ user = null ]) {
        this.$store.dispatch('manage/users/selectUser', user)
      },
    },
    headers() {
      return [
        {
          sortable: false,
          filterable: false,
          cellClass: 'px-1',
          value: 'avatar',
        },
        {
          text: 'Name',
          cellClass: 'text-no-wrap f-12 cursor-default',
          value: 'displayName',
        },
        {
          text: 'Email',
          cellClass: 'text-no-wrap f-12 cursor-default',
          value: 'email',
        },
        {
          text: 'Lehrkraft',
          align: 'center',
          cellClass: 'f-12 cursor-default',
          value: 'teacherCode',
        },
        {
          text: 'Rollen',
          align: 'center',
          sortable: false,
          cellClass: 'f-12 cursor-default',
          value: 'roles',
          filter: (value) => this.roles.length == 0 || this.roles.every((r) => value.includes(r)),
        },
        {
          text: 'Status',
          align: 'center',
          filterable: false,
          value: 'isActive',
        },
        {
          text: 'Letzte Anmeldung',
          align: 'center',
          filterable: false,
          cellClass: 'f-12 cursor-default',
          value: 'lastLogin',
        },
      ]
    },
    items() {
      return this.users.map((user) => ({
        ...user,
        teacherCode: this.teacherByIri(user.teacher)?.code || '',
      }))
    },
  },
}
</script>
