<template>
  <v-dialog
    v-model="dialog"
    max-width="580"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="savingUser"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">{{ editedUser ? 'mdi-account-edit-outline' : 'mdi-account-plus-outline' }}</v-icon>
        <div class="text-truncate">
          <span>
            {{ editedUser ? (editedUser.displayName || editedUser.email) : 'Benutzer hinzufügen' }}
          </span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :disabled="savingUser"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-actions
        v-if="!editedUser && teachersWithoutAccount.length > 0"
        class="px-6 pt-6"
      >
        <v-btn
          color="primary"
          small
          @click="openDialogImport()"
        >
          <v-icon
            left
            size="18"
          >mdi-account-multiple-plus-outline</v-icon>
          Lehrkräfte importieren
        </v-btn>
      </v-card-actions>

      <v-card-text class="mt-5">

        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="email"
              label="Email"
              outlined
              dense
              hide-details="auto"
              :disabled="savingUser"
              :error-messages="emailError"
              @input="emailError = null"
              @blur="validateEmail()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="displayName"
              label="Name"
              outlined
              dense
              hide-details="auto"
              :disabled="savingUser"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <RolesSelect
              v-model="roles"
              :disabled="savingUser"
            />
          </v-col>
        </v-row>

        <v-row v-if="showTeacherSelect">
          <v-col>
            <v-autocomplete
              v-model="teacher"
              :items="teachers"
              :item-text="(item) => `${item.displayName} (${item.code})`"
              return-object
              label="Lehrkraft"
              outlined
              dense
              hide-details="auto"
              clearable
              :disabled="savingUser"
              @change="onChangeTeacher"
            ></v-autocomplete>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-row
        class="px-4"
        no-gutters
      >
        <v-col
          v-if="!editedUser"
          class="px-2 pt-1 pb-4"
        >
          <v-checkbox
            v-model="sendWelcomeEmail"
            label="Enladungsemail rausschicken"
            dense
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              :disabled="savingUser"
              @click="dialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="primary"
              :loading="savingUser"
              :disabled="!email || !!emailError || !patch"
              @click="save()"
            >
              {{ editedUser ? 'Speichern' : 'Hinzufügen' }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import * as assert from '@/helpers/validators'
import RolesSelect from './roles-select'

export default {
  name: 'DialogEdit',
  components: {
    RolesSelect,
  },
  data() {
    return {
      displayName: '',
      email: '',
      emailError: null,
      error: null,
      roles: [],
      sendWelcomeEmail: true,
      teacher: null,
    }
  },
  computed: {
    ...mapState('manage/users', [
      'editedUser',
      'savingUser',
      'users',
    ]),
    ...mapGetters('common', [
      'teacherByIri',
    ]),
    ...mapGetters('manage/users', [
      'teachersWithoutAccount',
    ]),
    dialog: {
      get() {
        return this.$store.state.manage.users.dialogEdit
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/closeDialog')
        }
      },
    },
    editedUserRoles() {
      return this.editedUser?.roles?.filter((r) => r != 'ROLE_USER') || []
    },
    showTeacherSelect() {
      return this.roles.includes('ROLE_TEACHER') || this.roles.includes('ROLE_CLASSTEACHER')
    },
    teachers() {
      const teachers = this.teachersWithoutAccount
      if (!this.editedUser?.teacher) {
        return teachers
      }
      const currentTeacher = this.teacherByIri(this.editedUser.teacher)
      return [currentTeacher, ...teachers]
    },
    patch() {
      if (!this.editedUser) {
        return {
          displayName: this.displayName,
          email: this.email,
          roles: this.roles,
          teacher: this.teacher?.['@id'],
          sendWelcomeEmail: this.sendWelcomeEmail,
        }
      }
      const entries = [];
      const { displayName, email, teacher } = this.editedUser
      if (displayName !== this.displayName) {
        entries.push(['displayName', this.displayName]);
      }
      if (email !== this.email) {
        entries.push(['email', this.email]);
      }
      const roles = this.editedUserRoles
      if (!(roles.length == this.roles.length && this.roles.every((r) => roles.includes(r)))) {
        entries.push(['roles', this.roles]);
      }
      if (teacher !== this.teacher?.['@id']) {
        entries.push(['teacher', this.teacher?.['@id'] || null]);
      }
      return entries.length > 0 ? Object.fromEntries(entries) : null;
    },
  },
  watch: {
    dialog() {
      Object.assign(this, {
        displayName: this.editedUser?.displayName || '',
        email: this.editedUser?.email || '',
        emailError: null,
        error: null,
        roles: this.editedUserRoles,
        teacher: this.editedUser && this.teacherByIri(this.editedUser.teacher) || null,
      })
    },
  },
  methods: {
    openDialogImport() {
      this.$store.dispatch('manage/users/openDialog', {dialog: 'import'})
    },
    validateEmail() {
      const users = this.editedUser
        ? this.users.filter((u) => u['@id'] !== this.editedUser['@id'])
        : this.users
      const rules = [
        assert.required,
        assert.email,
        (email) => !users.some((u) => u.email === email) || 'Der Benutzer existiert bereits.',
      ]
      for (const validate of rules) {
        const result = validate(this.email)
        if (true !== result) {
          this.emailError = result
          return
        }
      }
      this.emailError = null
    },
    onChangeTeacher() {
      const { teacher } = this
      const newEmail = this.email || teacher?.email || ''
      if (newEmail !== this.email) {
        this.email = newEmail
        this.validateEmail()
      }
      this.displayName = this.displayName || (teacher && `${teacher.firstName} ${teacher.lastName}`) || ''
    },
    async save() {
      this.error = null
      this.validateEmail()
      if (this.emailError || !this.patch) return
      const { dispatch } = this.$store
      const action = this.editedUser ? 'patchUser' : 'createUser'
      const payload = this.editedUser ? {user: this.editedUser, patch: this.patch} : this.patch
      try {
        await dispatch(`manage/users/${action}`, payload)
      } catch (e) {
        this.error = e
      }
      if (!this.error) {
        dispatch('manage/users/fetchUsers', {silent: true})
        this.dialog = false
      }
    },
  },
}
</script>
