<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    right
    clipped
    touchless
    disable-route-watcher
    disable-resize-watcher
    class="d-print-none"
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '360'"
  >
    <template v-slot:prepend>
      <v-toolbar
        dense
        flat
        class="px-1"
      >
        <v-toolbar-title class="subtitle-2">
          Benutzerdaten:
          <strong>{{ selectedUser?.displayName || selectedUser?.email }}</strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="drawer = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
    </template>

    <div v-if="selectedUser">

      <v-toolbar
        dense
        flat
        class="px-1"
      >
        <v-btn
          text
          small
          color="primary"
          @click="$store.dispatch('manage/users/openDialog', {user: selectedUser})"
        >
          <v-icon left>mdi-pencil</v-icon>
          Bearbeiten
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu
          v-if="!isCurrentUser(selectedUser)"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="patchUser({sendWelcomeEmail: true})">
              <v-list-item-icon>
                <v-icon>mdi-email-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Enladungsemail rausschicken</v-list-item-title>
            </v-list-item>
            <v-list-item @click="patchUser({isActive: !selectedUser.isActive})">
              <v-list-item-icon>
                <v-icon :color="selectedUser.isActive ? 'error' : 'success'">
                  {{ selectedUser.isActive ? 'mdi-close-octagon' : 'mdi-checkbox-marked-circle' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ selectedUser.isActive ? 'Sperren' : 'Entsperren' }}
              </v-list-item-title>
            </v-list-item>
            <template v-if="!selectedUser.isActive">
              <v-divider></v-divider>
              <v-list-item @click="$store.dispatch('manage/users/openDialog', {dialog: 'delete', user: selectedUser})">
                <v-list-item-icon>
                  <v-icon color="error">mdi-delete-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="error--text">Löschen</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-simple-table class="text-caption pa-3">

        <tr>
          <th>
            Status:
          </th>
          <td>
            <StatusChip :is-active="selectedUser.isActive"/>
            <v-chip
              v-if="isCurrentUser(selectedUser)"
              small
              outlined
              class="ml-1 px-2 font-weight-bold"
              color="primary"
            >
              <v-avatar left>
                <v-icon small>mdi-lock-open-variant</v-icon>
              </v-avatar>
              Diese Sitzung
            </v-chip>
          </td>
        </tr>

        <tr>
          <th>
            Erstellt:
          </th>
          <td>
            {{ dateTime(selectedUser.createdAt) }}
          </td>
        </tr>

        <tr>
          <th>
            Letzte Anmeldung:
          </th>
          <td>
            {{ dateTime(selectedUser.lastLogin) || 'niemals' }}
          </td>
        </tr>

        <tr>
          <th>
            Name:
          </th>
          <td>
            {{ selectedUser.displayName }}
          </td>
        </tr>

        <tr>
          <th>
            Email:
          </th>
          <td>
            {{ selectedUser.email }}
          </td>
        </tr>

        <tr>
          <th>
            Rollen:
          </th>
          <td>
            <div
              v-for="role in shownRoles"
              :key="role.id"
            >
              <v-chip
                small
                outlined
                :color="role.color"
                class="text-caption font-weight-bold"
              >
                {{ role.name }}
              </v-chip>
              <small>
                {{ role.description }}
              </small>
            </div>
          </td>
        </tr>

        <tr>
          <th>
            Lehrkraft:
          </th>
          <td>
            {{ teacher ? `${teacher.displayName} (${teacher.code})` : '' }}
          </td>
        </tr>

      </v-simple-table>

      <div
        v-if="alert"
        class="d-flex justify-center ma-3"
      >
        <v-alert
          dense
          outlined
          :type="alert.type"
        >{{ alert.text }}</v-alert>
      </div>

    </div>

  </v-navigation-drawer>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import StatusChip from './status-chip'
import rolesItems from './roles'

export default {
  name: 'DrawerDetails',
  components: {
    StatusChip,
  },
  data: () => ({
    alert: null,
  }),
  computed: {
    ...mapState('manage/users', [
      'selectedUserIri',
    ]),
    ...mapGetters('manage/users', [
      'selectedUser',
      'isCurrentUser',
    ]),
    ...mapGetters('common', [
      'teacherByIri',
    ]),
    drawer: {
      get() {
        return this.$store.state.manage.users.drawerDetails
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/selectUser', null)
        }
      },
    },
    dateTime() {
      return (date) => date?.toLocaleDateString('de', {hour: 'numeric', minute: 'numeric'})
    },
    shownRoles() {
      return this.selectedUser?.roles
        .filter((roleId) => roleId !== 'ROLE_USER')
        .map((roleId) => rolesItems.find((role) => roleId === role.id) || {id: roleId, name: '?'})
    },
    teacher() {
      return this.teacherByIri(this.selectedUser.teacher)
    },
  },
  watch: {
    selectedUserIri() {
      this.alert = null
    },
  },
  methods: {
    async patchUser(patch) {
      this.alert = null
      const { dispatch } = this.$store
      try {
        await dispatch('manage/users/patchUser', {
          user: this.selectedUser,
          patch,
        })
      } catch (e) {
        this.alert = {type: 'error', text: e}
        return
      }
      dispatch('manage/users/fetchUsers', {silent: true})
      if (patch.sendWelcomeEmail) {
        this.alert = {
          type: 'success',
          text: 'Die Einladungsemail wurde erfolgreich rausgeschickt!',
        }
      }
    },
  },
}
</script>


<style lang="scss" scoped>
th {
  width: 1em;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
}

th, td {
  padding: 4px;
}
</style>
