export default [
  {
    id: 'ROLE_ADMIN',
    name: 'Administrator',
    short: 'ADM',
    description: '<kurze Beschreibung für die Administrator-Rolle>',
    color: 'error',
  },
  {
    id: 'ROLE_TEACHER',
    name: 'Fachlehrer',
    short: 'FL',
    description: '<kurze Beschreibung für die Fachlehrer-Rolle>',
    color: 'success',
  },
  {
    id: 'ROLE_CLASSTEACHER',
    name: 'Klassenlehrer',
    short: 'KL',
    description: '<kurze Beschreibung für die Klassenlehrer-Rolle>',
    color: 'success',
  },
  {
    id: 'ROLE_EDIT_LESSON_DATA_AS_ADMIN',
    name: 'Unterrichtsdaten',
    short: 'UDAT',
    description: '<kurze Beschreibung für die Unterrichtsdaten-Rolle>',
    color: 'warning',
  },
  {
    id: 'ROLE_EDIT_LESSON_REPLECEMENT_AS_ADMIN',
    name: 'Vertretung',
    short: 'VTRET',
    description: '<kurze Beschreibung für die Vertretung-Rolle>',
    color: 'warning',
  },
  {
    id: 'ROLE_EDIT_STUDENT_ABSENCE_AS_ADMIN',
    name: 'Abwesenheiten',
    short: 'ABWES',
    description: '<kurze Beschreibung für die Abwesenheiten-Rolle>',
    color: 'warning',
  },
]
